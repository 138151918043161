<template>
  <div
    ref="parent"
    class="relative modal-support d-flex white--bg"
    :class="{ 'p-color': partner }"
    :style="{
      '--mainColor': partner && color.main,
      '--font2': partner && color.font2,
      '--font3': partner && color.font3,
    }"
  >
    <button
      class="modal-support__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close.svg"
        alt="close"
      >
    </button>
    <div
      v-if="!qTypeResult"
      data-test="segmentation-modal"
      class="d-flex flex-column modal-support__wrap"
    >
      <div class="modal-support__title relative">
        <p class="headline-4">
          Вас интересует?
        </p>
      </div>

      <p
        data-test="new-connection-button"
        class="modal-support__chbx"
        :class="{ active: qType === 'new' }"
        @click="qType = 'new'"
      >
        <span
          class="white--text d-flex modal-support__chbx-ico"
          :class="qType === 'new' ? 'primary--bg' : 'white--bg'"
        >
          <span
            class="mask-icon mask-size-lg mask-ischecked"
          /></span>Новое подключение домашнего интернета
      </p>
      <p
        data-test="current-connection-button"
        class="modal-support__chbx"
        :class="{ active: qType === 'old' }"
        @click="qType = 'old'"
      >
        <span
          class="white--text d-flex modal-support__chbx-ico"
          :class="qType === 'old' ? 'primary--bg' : 'white--bg'"
        >
          <span
            class="mask-icon mask-size-lg mask-ischecked"
          /></span>Вопросы по действующему подключению
        <span class="gray-dark--text">(техподдержка, отключение, оплата)</span>
      </p>
      <d-btn
        data-test="choose-button-segmentation-modal"
        :disabled="!qType"
        class="modal-support__submit modal-support__chbx-submit"
        color="primary"
        size="medium"
        @click="selectQType"
      >
        Выбрать
      </d-btn>
    </div>
    <template v-else-if="qTypeResult === 'new'">
      <LazyPartnersModal
        :tariff="tariff"
        @close="$emit('close')"
      />
    </template>
    <div
      v-else
      data-test="segmentation-modal"
      class="d-flex flex-column modal-support__wrap"
    >
      <div class="modal-support__title relative">
        <p class="headline-4">
          Вопросы по действующему подключению
        </p>
      </div>

      <div class="modal-support__support">
        <p>Вы являетесь действующим абонентом {{ partner.provider.name }}</p>
        <p>
          Мы не сможем ответить на вопросы по действующему подключению или
          сменить ваш текущий тариф.
        </p>
        <p>Рекомендуем позвонить по номеру</p>
        <a
          v-if="phone"
          class="secondary-dark--text fwm"
          :href="'tel:' + phone.call_number"
          @click.prevent="callClick(phone.call_number, 'SHOW_SUPPORT')"
        >{{ phone.display_number }}</a>
        <span class="gray-dark--text d-block">Звонок бесплатный по РФ</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { createInteraction } from '~/composible/createInteraction'

const props = defineProps({
  target: {
    type: String,
  },
  tariff: {},
})
const emit = defineEmits(['close'])

const parent = ref()
const qType = ref()
const qTypeResult = ref()
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const providerClientCookie = useCookie('providerClient', { maxAge: 60 * 60 * 24 })
const partner = computed(() => mainStore.getDomainConfig)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const segmentation = computed(() => mainStore.getSegmentation)
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const color = computed(() => {
  if (partner.value?.provider.config?.color)
    return partner.value?.provider.config?.color
})
const phone = computed(() => mainStore.getCallNumber?.support)

const mainStore = useMainStore()

onBeforeMount(async () => {
  if (segmentationActive.value) {
    qType.value = 'old'
    selectQType()
  }
})
const { cI } = createInteraction()

const callClick = (number: string, type: string) => {
  window.open('tel:' + number, '_self')
  if (!interactionsSended.value) {
    mainStore.$patch({
      interactionsSended: true,
    })
    cI(
      true,
      undefined,
      { provider_id: partner.value?.provider.id },
      { request_type: type },
      undefined,
      undefined,
    )
  }
}
const selectQType = () => {
  qTypeResult.value = qType.value
  if (qType.value === 'old') {
    if (!interactionsSended.value) {
      mainStore.$patch({
        interactionsSended: true,
      })
      cI(
        true,
        undefined,
        { provider_id: partner.value?.provider.id },
        { request_type: 'SHOW_SUPPORT' },
        undefined,
        undefined,
      )
    }
    mainStore.$patch({
      oldClient: segmentation.value,
      providerClient: partner.value ? null : props?.tariff.provider?.id,
    })
    oldClientCookie.value = segmentation.value
    providerClientCookie.value = partner.value ? null : props?.tariff.provider?.id
  }
}
</script>

<style scoped lang="scss">
.modal-support {
  width: 600px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 16px 16px 0 0;
    height: 100%;
  }
  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: color(gray-darker);
    z-index: 1;
    img {
      opacity: .7;
      transition: opacity .24s;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__wrap {
    padding: 56px 24px;
  }

  &__support {
    p {
      font-size: fontSize(headline-8);
      line-height: 1.4;
      margin-bottom: 8px;

      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 17px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(title);
        line-height: 1.5;
      }
    }
    a {
      font-size: fontSize(headline-5);
      line-height: 1.4;
      transition: color 0.24s;
      &:hover {
        color: color(primary);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 23px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(headline-6);
      }
    }
    span {
      font-size: fontSize(label);
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 10px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(small);
      }
    }
  }

  &__chbx {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    margin-bottom: 16px;
    &-ico {
      position: absolute;
      border-radius: 50%;
      left: 0;
      border: 1px solid color(gray-darker);
    }

    &.active {
      .modal-support__chbx-ico {
        background: var(--mainColor);
        color: var(--font2);
        border-color: transparent;
      }
    }

    &-submit {
      margin-top: 8px;
      margin-bottom: 16px !important;
      margin-right: auto;
      padding: 16px 32px;
      &:not(:disabled),
      &:active {
        background: var(--mainColor);
        color: var(--font2);
      }
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    flex-wrap: wrap;
    width: unset;
    &.focus-active {
      .focus-hide {
        display: none;
      }
    }
  }
}
</style>
